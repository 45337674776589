import classNames from "@src/utilities/class-names"

function LogoSVG() {
  return (
    <svg
      className="absolute block bottom-0 h-full left-0 right-0 top-0 w-full"
      viewBox="0 0 216 30"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path
          className="fill-black"
          d="M9 3.443c-3.12 0-5.658 2.717-5.658 6.057 0 3.34 2.539 6.057 5.658 6.057 3.173 0 5.658-2.66 5.658-6.057 0-3.396-2.485-6.057-5.658-6.057M9 19c-4.879 0-9-4.35-9-9.5S4.121 0 9 0c4.963 0 9 4.261 9 9.5 0 5.238-4.037 9.5-9 9.5M37.966 18 28.424 5.801V18H25V0h3.058L37.6 12.227V0H41v18zM55.82 18v-6.606L49 0h3.961l4.514 7.736L62.015 0H66l-6.841 11.394V18zM85.86 18l-3.874-5.92L78.137 18H74l5.976-9.048L74.136 0h3.98l3.87 5.884L85.882 0h3.982l-5.84 8.952L90 18zM115 8.847h-5.847V3h-2.306v5.847H101v2.306h5.847V17h2.306v-5.847H115z"
        />
        <path
          className="fill-black"
          d="M0 30h216v-2H0zM133.416 9.702h4.903c2.105 0 3.412-1.25 3.412-3.264 0-2.075-1.276-3.314-3.412-3.314h-4.903v6.578zM140.635 18l-3.618-5.2h-3.601V18H130V0h8.588C142.603 0 145 2.388 145 6.388c0 2.7-1.222 4.819-3.353 5.81l-.76.354L144.72 18h-4.085zM161 3.443c-3.12 0-5.658 2.717-5.658 6.057 0 3.34 2.539 6.057 5.658 6.057 3.173 0 5.658-2.66 5.658-6.057 0-3.397-2.485-6.057-5.658-6.057M161 19c-4.879 0-9-4.35-9-9.5s4.121-9.5 9-9.5c4.963 0 9 4.261 9 9.5 0 5.238-4.037 9.5-9 9.5M185.76 19c-4.49 0-7.47-1.889-7.76-4.855h3.594c.39 1.008 1.787 1.916 4.062 1.916 3.082 0 3.322-1.471 3.322-1.922 0-1.399-1.562-2.022-3.943-2.813-3.496-1.13-6.904-2.516-6.904-5.936 0-3.274 2.792-5.39 7.112-5.39 4.275 0 7.208 2.075 7.456 5.208h-3.558c-.305-1.178-1.614-2.27-3.795-2.27-2.375 0-3.218 1.104-3.218 2.048 0 1.373 1.609 1.913 3.836 2.661 2.82.97 7.036 2.418 7.036 6.215 0 3.788-3.74 5.138-7.24 5.138M202 18V0h14v3.422h-10.349v4.19h8.508v3.1h-8.508v3.891H216V18z"
        />
      </g>
    </svg>
  )
}

export interface LogoProps {
  additionalClasses?: string[]
  ariaLabel?: string
  asLink?: boolean
  linkTitle?: string
  url?: string
}
export default function Logo({
  additionalClasses = [],
  ariaLabel = "Onyx + Rose",
  asLink = false,
  linkTitle,
  url,
}: LogoProps) {
  const classes = classNames(
    "block",
    "relative",
    "before:block",
    "before:content-['']",
    "before:pt-[13.88888%]",
    ...additionalClasses
  )
  if (asLink && url) {
    return (
      <a
        aria-label={ariaLabel}
        className={classes}
        href={url}
        title={linkTitle}
        data-component="logo"
      >
        <LogoSVG />
      </a>
    )
  }
  return (
    <div aria-label={ariaLabel} className={classes} data-component="logo">
      <LogoSVG />
    </div>
  )
}
