import Head from "next/head"

interface SEOProps {
  description?: string | null
  og_description?: string | null
  og_image?: string | null
  og_title?: string | null
  noIndex?: boolean
  title?: string | null
  twitter_description?: string | null
  twitter_image?: string | null
  twitter_title?: string | null
}
export default function SEO({
  description,
  og_description,
  og_image,
  og_title,
  noIndex = false,
  title,
  twitter_description,
  twitter_image,
  twitter_title,
}: SEOProps) {
  return (
    <Head>
      <title>{title && title.length > 0 ? title : "Onyx + Rose"}</title>
      {description && description.length > 0 && (
        <meta name="description" content={description} />
      )}
      <meta
        name="google-site-verification"
        content="LSOo6pyBXLnMC3SYCq6AF36lCdN-qcLgXjT6Jos_tOE"
      />
      {noIndex && <meta name="robots" content="noindex,noarchive,follow" />}
      <meta property="og:site_name" content="Onyx + Rose" />
      {og_description && og_description.length > 0 && (
        <meta property="og:description" content={og_description} />
      )}
      {og_image && og_image.length > 0 && (
        <meta property="og:image" content={og_image} />
      )}
      {og_title && og_title.length > 0 && (
        <meta property="og:title" content={og_title} />
      )}
      <meta
        name="twitter:card"
        content={
          twitter_image && twitter_image.length > 0
            ? "summary_large_image"
            : "summary"
        }
      />
      {twitter_description && twitter_description.length > 0 && (
        <meta name="twitter:description" content={twitter_description} />
      )}
      {twitter_title && twitter_title.length > 0 && (
        <meta name="twitter:title" content={twitter_title} />
      )}
    </Head>
  )
}
