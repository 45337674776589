import {
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa"

import Logo from "@src/components/Logo"
import CreditCardBrands from "@src/components/CreditCardBrands"

import type { SiteSettings } from "@src/entities/types/site-settings"

const socialIcons = {
  facebook: <FaFacebookF />,
  instagram: <FaInstagram />,
  pinterest: <FaPinterestP />,
  twitter: <FaTwitter />,
  tiktok: <FaTiktok />,
  youtube: <FaYoutube />,
}

export interface FooterProps {
  bottomImageUrl?: string
  disclosureText: string
  navigationMenu: SiteSettings["footerNavigationMenu"]
  socialLinks: SiteSettings["socialLinks"]
}
export default function Footer({
  bottomImageUrl,
  disclosureText,
  navigationMenu,
  socialLinks,
}: FooterProps) {
  return (
    <footer className="bg-gray-300 mt-44" data-component="footer">
      <div className="max-w-5xl mx-auto py-16 lg:py-24 px-4">
        <div className="md:grid md:grid-cols-2">
          <div>
            <Logo
              additionalClasses={["mb-10", "w-48", "mx-auto", "md:mx-0"]}
              asLink={true}
              linkTitle="Onyx + Rose Home"
              url="/"
            />
            <div className="flex align-center justify-center mb-12 md:justify-start md:mb-0">
              {socialLinks.map((item) => (
                <a
                  aria-label={`Check out Onyx + Rose on ${item.platform}`}
                  className="text-2xl mr-4 hover:text-orange transition-colors"
                  href={item.url}
                  key={item.platform}
                  rel="noopener noreferrer"
                  target="_blank"
                  title={`Follow us on ${item.platform}`}
                >
                  {socialIcons[item.platform]}
                </a>
              ))}
            </div>
          </div>
          <nav className="columns-2 font-sans">
            {navigationMenu &&
              navigationMenu.items.map((item: any, idx: number) => (
                <a
                  className="block uppercase font-semibold mb-4 text-sm hover:text-orange transition-colors"
                  key={`footer_menu_item_${idx}`}
                  href={item.url}
                >
                  {item.text}
                </a>
              ))}
          </nav>
        </div>
        <div className="mt-16 text-gray/90 text-xs">
          <div className="mb-2">
            &copy; 2019-{new Date().getFullYear()} Onyx &amp; Rose LLC. All
            Rights Reserved.
          </div>
          <div
            className="mb-8"
            dangerouslySetInnerHTML={{ __html: disclosureText }}
          />
          <CreditCardBrands />
        </div>
      </div>
      <div
        className="bg-center bg-cover h-32 lg:h-64"
        style={{ backgroundImage: `url(${bottomImageUrl})` }}
      />
    </footer>
  )
}
