export function formatCurrency(
  priceInThousands: number,
  trimIfNoCents: boolean = false
): string {
  return new Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits:
      trimIfNoCents && priceInThousands % 100 === 0 ? 0 : 2,
    maximumFractionDigits:
      trimIfNoCents && priceInThousands % 100 === 0 ? 0 : 2,
    style: "currency",
  }).format(0.01 * priceInThousands)
}

export function formatNumberWithSeparators(
  num: number,
  maxDecimalPlaces: number = 0,
  minDecimalPlaces: number = 0
): string {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDecimalPlaces,
    maximumFractionDigits: maxDecimalPlaces,
  }).format(num)
}

export function formatPriceAsFloat(
  num: number,
  maxDecimalPlaces: number = 2
): number {
  return Number.parseFloat((0.01 * num).toFixed(maxDecimalPlaces))
}

export function formatPriceAsFloatString(
  num: number,
  maxDecimalPlaces: number = 2
): string {
  return (0.01 * num).toFixed(maxDecimalPlaces)
}
