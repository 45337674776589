import { useEffect, useState } from "react"

import useSiteScrolled from "@src/hooks/use-site-scrolled"

import Footer from "@src/components/Footer"
import SmartHeader from "@src/components/SmartHeader"
import OffCanvasNavigation from "@src/components/OffCanvasNavigation"

import type { SiteSettings } from "@src/entities/types/site-settings"

export interface GeneralLayoutProps {
  children?: React.ReactNode
  siteSettings: SiteSettings
}

export default function GeneralLayout({
  children,
  siteSettings,
}: GeneralLayoutProps) {
  const isSiteScrolled = useSiteScrolled()

  const [offCanvasNavOpen, setOffCanvasNavOpen] = useState<boolean>(false)

  const toggleOcn: React.MouseEventHandler<HTMLButtonElement> = () => {
    setOffCanvasNavOpen((prevState) => !prevState)
  }

  const onResize = () => {
    setOffCanvasNavOpen(false)
  }

  useEffect(() => {
    if (offCanvasNavOpen) {
      document.body.classList.add("fixed")
    } else {
      document.body.classList.remove("fixed")
    }
    return () => {
      document.body.classList.remove("fixed")
    }
  }, [offCanvasNavOpen])

  useEffect(() => {
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <>
      <SmartHeader
        isScrolled={isSiteScrolled}
        onMenuButtonClick={toggleOcn}
        preheaderMessage={siteSettings.alertBarHtml}
        preheaderNavigationMenu={siteSettings.preheaderNavigationMenu}
        primaryNavigationMenu={siteSettings.primaryNavigationMenu}
        secondaryNavigationMenu={siteSettings.secondaryNavigationMenu}
      />
      <OffCanvasNavigation
        isOpen={offCanvasNavOpen}
        navMenu={siteSettings.primaryNavigationMenuMobile}
      />
      <main>{children}</main>
      <Footer
        bottomImageUrl={siteSettings.footerImageUrl}
        disclosureText={siteSettings.fdaDisclosure}
        navigationMenu={siteSettings.footerNavigationMenu}
        socialLinks={siteSettings.socialLinks}
      />
    </>
  )
}
