import React from "react"

export default function useSiteScrolled() {
  const [isScrolled, setIsScrolled] = React.useState<boolean>(false)
  const [scrollIsTicking, setScrollIsTicking] = React.useState<boolean>(false)

  const listenForScroll = () => {
    if (!scrollIsTicking) {
      window.requestAnimationFrame(() => {
        setScrollIsTicking(false)
        setIsScrolled(window.scrollY > 0)
      })
      setScrollIsTicking(true)
    }
  }

  React.useEffect(() => {
    document.addEventListener("scroll", listenForScroll)
    return () => {
      document.removeEventListener("scroll", listenForScroll)
    }
  }, [])

  return React.useMemo(() => {
    return isScrolled
  }, [isScrolled])
}
