import classNames from "@src/utilities/class-names"

interface CreditCardBrandsProps {
  size?: "regular" | "small"
}
export default function CreditCardBrands({
  size = "regular",
}: CreditCardBrandsProps) {
  return (
    <div
      className="flex items-center justify-start"
      data-component="credit-card-brands"
    >
      <img
        alt="American Express"
        className={classNames(
          "mr-2.5",
          size === "regular" ? "max-w-[2.4rem]" : "max-w-[1.5rem]"
        )}
        height="299"
        src="/images/amex.png"
        width="300"
      />
      <img
        alt="Discover"
        className={classNames(
          "mr-2.5",
          size === "regular" ? "max-w-[2.4rem]" : "max-w-[1.5rem]"
        )}
        height="192"
        src="/images/discover.png"
        width="300"
      />
      <img
        alt="Mastercard"
        className={classNames(
          "mr-2.5",
          size === "regular" ? "max-w-[2.4rem]" : "max-w-[1.5rem]"
        )}
        height="180"
        src="/images/mastercard.png"
        width="300"
      />
      <img
        alt="Visa"
        className={classNames(
          size === "regular" ? "max-w-[2.4rem]" : "max-w-[1.5rem]"
        )}
        height="202"
        src="/images/visa.png"
        width="300"
      />
    </div>
  )
}
