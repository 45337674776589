import React from "react"
import { BsPlusCircle } from "react-icons/bs"

import classNames from "@src/utilities/class-names"

export interface OffCanvasNavigationProps {
  isOpen: boolean
  navMenu?: onyx.Types.NavigationMenu
}

const primaryLinkClassNames = classNames(
  "bg-transparent border-0 block font-bold px-4 py-2 text-left text-white tracking-wide uppercase w-full"
)

const submenuLinkClassNames = classNames(
  "block px-4 py-2 font-bold text-sm text-black tracking-wide uppercase"
)

export default function OffCanvasNavigation({
  isOpen,
  navMenu,
}: OffCanvasNavigationProps) {
  const toggleSubmenus = (
    elements: HTMLCollection,
    thisElementIndex: number
  ) => {
    for (let i = 0; i < elements.length; i += 1) {
      if (i !== thisElementIndex) {
        elements[i].setAttribute("data-ocn-submenu-open", "false")
      }
    }
    elements[thisElementIndex].setAttribute("data-ocn-submenu-open", "true")
  }

  const ref = React.useCallback((node: any) => {
    if (node !== null) {
      const itemsWithSubmenus: HTMLCollection = node.getElementsByClassName(
        "ocn-item-has-children"
      )
      for (let i = 0; i < itemsWithSubmenus.length; i += 1) {
        itemsWithSubmenus[i].setAttribute("data-ocn-submenu-open", "false")
        itemsWithSubmenus[i].childNodes[0].addEventListener("click", (ev) => {
          ev.preventDefault()
          toggleSubmenus(itemsWithSubmenus, i)
        })
      }
      itemsWithSubmenus[0].setAttribute("data-ocn-submenu-open", "true")
      return () => {
        for (let i = 0; i < itemsWithSubmenus.length; i += 1) {
          itemsWithSubmenus[i].setAttribute("data-ocn-submenu-open", "false")
          itemsWithSubmenus[i].childNodes[0].removeEventListener(
            "click",
            (ev) => {
              ev.preventDefault()
              toggleSubmenus(itemsWithSubmenus, i)
            }
          )
        }
      }
    }
    return () => {}
  }, [])

  return (
    <nav
      className="bg-black fixed bottom-0 text-white flex flex-col font-sans justify-between left-0 opacity-0 overflow-y-auto -translate-x-full transition-transform transition-opacity top-[84px] sm:top-[102px] lg:top-[120px] w-full z-[900] xl:hidden"
      data-ocn-open={isOpen === true}
      data-component="off-canvas-navigation"
    >
      <ol className="py-2" ref={ref}>
        {navMenu?.items.map((item, idx) => (
          <li
            className={item.items?.length ? "ocn-item-has-children" : ""}
            key={`ocn-menu-item-${idx}`}
          >
            {item.items?.length && (
              <>
                <button
                  className={classNames(
                    primaryLinkClassNames,
                    "flex items-center relative justify-between"
                  )}
                >
                  <span
                    className="block"
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                  <BsPlusCircle className="-mt-2" />
                </button>
                <ol className="bg-white hidden py-2">
                  {item.items.map((subitem, subidx) => (
                    <li key={`ocn-menu-item-${idx}-submenu-${subidx}`}>
                      <a
                        className={submenuLinkClassNames}
                        dangerouslySetInnerHTML={{ __html: subitem.text }}
                        href={subitem.url}
                      />
                    </li>
                  ))}
                </ol>
              </>
            )}
            {!item.items?.length && (
              <a
                className={primaryLinkClassNames}
                href={item.url}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            )}
          </li>
        ))}
      </ol>
      <ol className="pb-2">
        <li>
          <a className={primaryLinkClassNames} href="/account">
            My Account
          </a>
        </li>
        <li>
          <a
            className={primaryLinkClassNames}
            href="mailto:help@onyxandrose.com"
          >
            Contact Us
          </a>
        </li>
      </ol>
    </nav>
  )
}
