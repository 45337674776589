export interface PreHeaderProps {
  message?: string
  navigationMenu?: onyx.Types.NavigationMenu
}
export default function PreHeader({ message, navigationMenu }: PreHeaderProps) {
  return (
    <div
      className="bg-black text-xs text-white leading-none tracking-widest px-4 py-1 sm:font-semibold sm:pt-4 sm:pb-3"
      data-component="preheader"
    >
      <div className="relative mx-auto max-w-screen-2xl">
        <div
          className="text-center whitespace-nowrap"
          dangerouslySetInnerHTML={{ __html: message ?? "&nbsp;" }}
        />
        {navigationMenu && (
          <nav className="absolute flex items-center hidden top-0 right-0 uppercase sm:block">
            {(navigationMenu.items ?? []).map((item, idx) => (
              <a className="pr-4" href={item.url} key={`preheader_nav_${idx}`}>
                {item.text}
              </a>
            ))}
            <a href="/account">My Account</a>
          </nav>
        )}
      </div>
    </div>
  )
}
